.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.App-header {
  height: 7vh;
  padding: 0 0.25em 0 1em;
  background-color: #252837;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(8px + 2vmin);
  font-family: Arial, Helvetica, sans-serif;
}

.header-text {
  color: #fbfbfb;
}
.App-footer {
  height: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(4px + 1vmin);
}

.App-footer a {
  color: #000;
}

.forgot-password {
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.App-content {
  height: 91vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-input-affix-wrapper {
  border-radius: 25px !important;
}

.link-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ant-card-body {
  padding: 8px !important;
}

.custom-row {
  margin: 0 !important;
}

.map-container {
  margin: 0 !important;
}

.content-top {
  height: 6vh;
  width: 100vw;
}

.content-bottom {
  width: 100vw;
  height: 85vh;
  display: flex;
  flex-direction: row;
}

.bottom {
  width: 50vw;
}

.title-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

[data-theme="compact"] .ant-advanced-search-form,
.ant-advanced-search-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
  display: flex;
  margin: 0;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.text-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}

.selection-container {
  margin: 15px;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
}

.select-option-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.region-text {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}
